import React from 'react';
import Header from "../layout/Header";
import { useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux";
import {AGModalOpen} from "../../store/slices/main.slice";

const Item = ({ data, divider }: any) => {
    const dispatch: any = useDispatch();

    const openModal = () => {
        dispatch(AGModalOpen(true))
    }

    return (
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/4 px-[20px] pb-[30px] md:pb-[70px]">
            <div className="relative flex flex-col items-center justify-center w-full md:w-[75%]">
                <div onClick={openModal} className="absolute inset-0 flex items-center justify-center cursor-pointer z-[2]">
                    <img src={`/images/play-icon.png`} alt="Play icon" className="h-[20px]"/>
                </div>
                <img src={`/images/percorsi/thumbs/${data.image}`} alt="Percorso image" className="h-auto"/>
                {divider && <div className="hidden md:flex absolute inset-0 flex-col items-center justify-center"><img src={`/images/gndm_shape_arrow.png`} alt="Arrow image" className="h-[25px] w-auto ml-[150%]"/></div>}
            </div>
            <h3 className="text-black text-sm md:text-base font-bold leading-[1.3] pt-[15px]">{data.title}</h3>
            {divider && <div className="flex md:hidden flex-col items-center justify-center"><img src={`/images/gndm_shape_arrow.png`} alt="Arrow image" className="h-[30px] w-auto mt-[30px] rotate-[90deg]"/></div>}
        </div>
    );
};

const SinglePercorso = () => {
    const navigate = useNavigate();

    const data = [
        {title: 'Le donazioni Volponi', duration: 'Durata 30 minuti', image: 'percorso-1.png'},
        {title: 'Le donazioni Volponi', duration: 'Durata 30 minuti', image: 'percorso-2.png'},
        {title: 'Le donazioni Volponi', duration: 'Durata 30 minuti', image: 'percorso-3.png'},
        {title: 'Le donazioni Volponi', duration: 'Durata 30 minuti', image: 'percorso-1.png'},
    ]

    return (
        <div className="relative flex flex-col items-start h-[100dvh] w-screen overflow-hidden">
            <div className="w-full h-max z-[2] bg-[#e4dbd6]">
                <Header version="dark"/>
            </div>
            <div className="w-full h-full overflow-y-auto">
                <div className="flex flex-col md:flex-row gap-[100px] w-full lg:mt-[60px] md:mt-[30px] mt-[60px] px-[20px] md:px-[30px] lg:px-[100px] overflow-y-auto overflow-hidden h-max">
                    <section className="w-full md:w-1/2 h-max">
                        <div className="flex flex-row items-start gap-[20px] mb-[30px] lg:mb-[50px] md:mb-[20px]">
                            <img onClick={() => navigate('/percorsi')} src={`/images/arrow-back-black.png`} alt="Back icon" className="lg:h-[30px] h-[20px] w-auto mt-[5px] md:mt-[12px] cursor-pointer"/>
                            <div>
                                <h2 className="text-black md:text-[28px] lg:text-[40px] text-[22px] font-untitledSans">Le donazioni Volponi</h2>
                                <h3 className="text-[#ec651f] text-xs md:text-base lg:text-md">Durata 30 minuti</h3>
                            </div>
                        </div>
                        <p className="text-black text-xs md:text-sm text-justify">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit, amet, consectetur, adipisci velit,
                            sed
                            quia non numquam eius modi tempora incidunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.
                        </p>
                    </section>
                    <section className="w-full md:w-1/2 flex flex-col items-center lg:items-center md:items-start md:mt-[60px] justify-center md:justify-start lg:justify-center mt-[-60px] lg:mt-0">
                        <img src={`/images/planimetria.png`} alt="Planimetria" className="h-auto w-full lg:w-[75%]"/>
                    </section>
                </div>
                <div className="hidden md:flex flex-wrap justify-start w-full mt-[150px] md:mt-[100px] px-[30px] lg:px-[80px] pb-[100px] md:pb-0">
                    {data.map((item, _i) => {
                        return <Item key={`item-${_i}`} data={item} divider={_i < data.length - 1}/>
                    })}
                </div>
                <div className="flex md:hidden flex-col items-center justify-center w-full mt-[100px] md:mt-[100px] px-[30px] lg:px-[80px] pb-[100px] md:pb-0">
                    {data.map((item, _i) => {
                        return <Item key={`item-${_i}`} data={item} divider={_i < data.length - 1}/>
                    })}
                </div>
                <div className="flex md:hidden absolute left-0 bottom-0 z-[900] w-full bg-black/95 h-[100px] flex-row items-center justify-between px-[20px]">
                    <div className="w-[80px] flex flex-col items-center gap-[7px] cursor-pointer">
                        <img src={`/images/prev-arrow-icon.svg`} alt="Inquadra-icona" className="h-[20px]"/>
                        <span className="text-white text-xs text-center font-untitledSans">previous</span>
                    </div>
                    <div className="flex flex-col items-center gap-[7px] cursor-pointer">
                        <img src={`/images/icona-inquadra.png`} alt="Inquadra-icona" className="h-[30px]"/>
                        <span className="text-white text-xs text-center font-untitledSans">inquadra</span>
                    </div>
                    <div className="w-[80px] flex flex-col items-center gap-[7px] cursor-pointer">
                        <img src={`/images/next-arrow-icon.svg`} alt="Inquadra-icona" className="h-[20px]"/>
                        <span className="text-white text-xs text-center font-untitledSans">next</span>
                    </div>
                </div>
                <div className="hidden md:flex lg:hidden absolute left-0 bottom-0 z-[900] w-full bg-black/95 h-[60px] flex-row items-center justify-between px-[20px]">
                    <div className="flex flex-row items-center gap-[10px] cursor-pointer">
                        <img src={`/images/prev-arrow-icon.svg`} alt="Inquadra-icona" className="h-[20px]"/>
                        <span className="text-white text-xs text-center font-untitledSans">previous</span>
                    </div>
                    <div className="flex flex-row items-center gap-[10px] cursor-pointer">
                        <img src={`/images/icona-inquadra.png`} alt="Inquadra-icona" className="h-[30px]"/>
                        <span className="text-white text-xs text-center font-untitledSans">inquadra</span>
                    </div>
                    <div className="flex flex-row items-center gap-[10px] cursor-pointer">
                        <span className="text-white text-xs text-center font-untitledSans">next</span>
                        <img src={`/images/next-arrow-icon.svg`} alt="Inquadra-icona" className="h-[20px]"/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SinglePercorso;
