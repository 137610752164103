import React, {useState} from 'react';
import Header from "../layout/Header";
import {useDispatch} from "react-redux";
import {AGModalOpen} from "../../store/slices/main.slice";
import QRScanner from "../QRScanner";

const Item = ({ data }: any) => {
    const dispatch: any = useDispatch();
    const openModal = () => {
        dispatch(AGModalOpen(true))
    }

    return (
        <>
            {/*Desktop*/}
            <div className="hidden lg:block w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-[20px] pb-[70px]">
                <div className="relative flex flex-col items-center justify-center w-100 md:w-[75%]">
                    <div onClick={openModal} className="absolute inset-0 flex items-center justify-center cursor-pointer">
                        <img src={`/images/play-icon.png`} alt="Play icon" className="h-[20px]"/>
                    </div>
                    <img src={`/images/collezione/thumbs/${data.image}`} alt="Collection image" className="h-auto md:w-auto w-full"/>
                </div>
                <h3 className="text-black text-base font-bold leading-[1.3] pt-[15px]">{data.title}</h3>
                <h4 className="text-black text-sm">{data.author}</h4>
            </div>
            {/*Mobile landscape*/}
            <div className="hidden md:flex lg:hidden flex-row items-start gap-[20px] w-full md:w-1/2 px-[20px] pb-[40px]">
                <div className="relative flex flex-col items-center justify-center w-[120px] min-w-[120px] max-w-[120px]">
                    <div onClick={openModal} className="absolute inset-0 flex items-center justify-center cursor-pointer">
                        <img src={`/images/play-icon.png`} alt="Play icon" className="h-[20px]"/>
                    </div>
                    <img src={`/images/collezione/thumbs/${data.image}`} alt="Collection image" className="h-auto w-full"/>
                </div>
                <div className="flex flex-col">
                    <h3 className="text-black text-sm font-bold leading-[1.3]">{data.title}</h3>
                    <h4 className="text-black text-xs">{data.author}</h4>
                </div>
            </div>
            {/*Mobile portrait*/}
            <div className="md:hidden flex flex-row items-start gap-[20px] w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-[20px] pb-[40px]">
                <div className="relative flex flex-col items-center justify-center w-[120px] min-w-[120px] max-w-[120px]">
                    <div onClick={openModal} className="absolute inset-0 flex items-center justify-center cursor-pointer">
                        <img src={`/images/play-icon.png`} alt="Play icon" className="h-[20px]"/>
                    </div>
                    <img src={`/images/collezione/thumbs/${data.image}`} alt="Collection image" className="h-auto w-full"/>
                </div>
                <div className="flex flex-col">
                    <h3 className="text-black text-sm font-bold leading-[1.3]">{data.title}</h3>
                    <h4 className="text-black text-xs">{data.author}</h4>
                </div>
            </div>
        </>
    );
};

const Collezione = () => {

    const data = [
        {title: 'Ritratto di gentildonna (La Muta)', author: 'Raffaello Sanzio', image: 'collezione-1.png'},
        {title: 'Annunciazione', author: 'Alessandro Vitali', image: 'collezione-2.png'},
        {title: 'Sant’Agata In Carcere', author: 'Alessandro Vitali', image: 'collezione-3.png'},
        {title: 'Ultima Cena', author: 'Tiziano Vacellio', image: 'collezione-4.png'},
        {title: 'David in contemplazione della testa di Golia', author: 'Orazio Gentileschi', image: 'collezione-5.png'},
        {title: 'San Francesco riceve le stigmate', author: 'Federico Barocci', image: 'collezione-6.png'},
        {title: 'Ritratto di Federico da Montefeltro e ...', author: 'Pedro Berruguete', image: 'collezione-7.png'},
        {title: 'Città Ideale', author: 'Anonimo Artista dell\'Italia Centrale', image: 'collezione-8.png'},
        {title: 'Flagellazione', author: 'Piero Della Francesca', image: 'collezione-9.png'},
        {title: 'La Crocifissione & La Discesa Dello ...', author: 'Luca Signorelli', image: 'collezione-10.png'},
        {title: 'Miracolo Dell’Ostia Profanata', author: 'Paolo Uccello', image: 'collezione-11.png'},
        {title: 'La Visione Di Santa Francesca Romana', author: 'Orazio Gentileschi', image: 'collezione-12.png'},
    ]

    const [scanning, setScanning] = useState(false);

    // QR Code scanning
    const handleScanResult = (data: string) => {
        if (data) {
            window.location.href = data;
        }
    };

    const startScanner = () => {
        console.log('test')
        setScanning(true);
    };

    const stopScanner = () => {
        setScanning(false);
    };

    return (
        <>
            {scanning && <QRScanner onScan={handleScanResult} onClose={stopScanner}/>}
            <div className="relative flex flex-col items-start h-[100dvh] w-screen overflow-hidden">
                <div className="w-full h-max z-[2] bg-[#e4dbd6]">
                    <Header version="dark"/>
                </div>
                <div className="flex flex-col w-full h-full lg:mt-[60px] md:mt-[30px] mt-[30px] px-[20px] md:px-[30px] lg:px-[100px] overflow-y-auto overflow-x-hidden">
                    <div className="w-full flex flex-row items-center lg:gap-[30px] gap-[20px] mb-[40px] lg:mb-[60px] ml-[20px]">
                        <img src={`/images/gndm_shape_collezione.png`} alt="Shape icon" className="h-[30px] lg:h-[50px]"/>
                        <h2 className="text-black md:text-[28px] lg:text-[40px] text-[28px] font-untitledSans uppercase mb-[-5px]">La collezione</h2>
                    </div>
                    <div className="flex flex-wrap justify-start w-full">
                        {data.map((item, _i) => {
                            return <Item key={`item-${_i}`} data={item}/>
                        })}
                    </div>
                </div>
            </div>
            <div className="flex md:hidden absolute left-0 bottom-0 z-[900] w-full bg-black/95 h-[100px] flex-row items-center justify-center px-[20px]">
                {!scanning && <div onClick={startScanner} className="flex flex-col items-center gap-[7px] cursor-pointer">
                    <img src={`/images/icona-inquadra.png`} alt="Inquadra-icona" className="h-[30px]"/>
                    <span className="text-white text-xs text-center font-untitledSans">inquadra</span>
                </div>}
            </div>
            <div className="hidden md:flex lg:hidden absolute left-0 bottom-0 z-[900] w-full bg-black/95 h-[60px] flex-row items-center justify-center px-[20px]">
                {!scanning && <div onClick={startScanner} className="flex flex-row items-center gap-[10px] cursor-pointer">
                    <img src={`/images/icona-inquadra.png`} alt="Inquadra-icona" className="h-[30px]"/>
                    <span className="text-white text-xs text-center font-untitledSans">inquadra</span>
                </div>}
            </div>
        </>
    )
};

export default Collezione;
