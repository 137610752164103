import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode, Html5QrcodeScannerState } from 'html5-qrcode';

interface QRScannerProps {
    onScan: (data: string) => void;
    onClose: () => void;
}

const QRScanner: React.FC<QRScannerProps> = ({ onScan, onClose }) => {
    const [scanning, setScanning] = useState(true);
    const scannerRef = useRef<HTMLDivElement>(null);
    const html5QrCode = useRef<Html5Qrcode | null>(null);

    useEffect(() => {
        if (scannerRef.current && scanning) {

            let qrboxFunction = function(viewfinderWidth: any, viewfinderHeight: any) {
                let minEdgePercentage = 0.7;
                let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
                let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
                return {
                    width: qrboxSize,
                    height: qrboxSize
                };
            }

            html5QrCode.current = new Html5Qrcode("scanner");

            html5QrCode.current.start(
                { facingMode: "environment" },  // Use the rear camera
                {fps: 10, aspectRatio: 1.1},
                (decodedText) => {
                    onScan(decodedText);  // Pass the scanned QR code to parent component
                    stopScanning();  // Stop scanning after successful scan
                },
                (errorMessage) => {}
            )
        }

        // Cleanup scanner when component is unmounted
        return () => {
            if (html5QrCode.current) {
                if(html5QrCode.current?.getState() === Html5QrcodeScannerState.SCANNING || html5QrCode.current?.getState() === Html5QrcodeScannerState.PAUSED) {
                    html5QrCode.current.stop().then(() => {
                        html5QrCode.current?.clear();
                        onClose()
                    })
                }
            }
        };
    }, [scanning, onScan]);

    const stopScanning = () => {
        setScanning(false);
        if (html5QrCode.current) {
            if(html5QrCode.current?.getState() === Html5QrcodeScannerState.SCANNING || html5QrCode.current?.getState() === Html5QrcodeScannerState.PAUSED) {
                html5QrCode.current.stop().then(() => {
                    html5QrCode.current?.clear();
                    onClose()
                })
            }
        }
    };

    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black z-[1000]">
            <div className="h-screen w-screen flex flex-col items-center justify-center p-[60px]">
                <div onClick={onClose} className="absolute top-[40px] right-[40px] text-white text-md font-untitledSans font-bold cursor-pointer">X</div>
                <p className="text-white font-untitledSans text-center mb-[25px]">Inquadra in QR code</p>
                <div id="scanner" ref={scannerRef} className="w-[70vw] h-[70vw] relative overflow-hidden"></div>
            </div>
        </div>
    );
};

export default QRScanner;